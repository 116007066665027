import React, {useEffect, useState, useMemo} from 'react';
import { is } from "ramda";
import AuthContext from "./authcontext";
import { AuthSkipCountries } from "./authSkipCountry";

export const AuthBasedRender = ({ role, children, isVisible, title, name }) => {
    const context = React.useContext(AuthContext);
    const isSkip = useMemo(() => {
        return name && AuthSkipCountries[name] && AuthSkipCountries[name].includes(context.countryCode);
    }, [name, context.countryCode]);
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        const isValidRole = is(Number, role) && context.role >= role;
        setIsAuthorized(isValidRole && !isSkip);
    }, [role, context.role, isSkip]);

    return (
    <>
        { isAuthorized && children}
    </>
    )
}

