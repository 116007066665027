import Nav from 'react-bootstrap/Nav';
import React from "react";
import styles from "./tabtitlebar.module.scss";
import { AuthBasedRender } from "../stores/auth/authbasedrender";
import { AuthRoles } from "../stores/auth/authRole";

const TabTitleBar = props => (
    <Nav variant="tabs" className={`flex-row ${styles.tab_title_bar}`}>
        <h4>{props.title}</h4>
        <AuthBasedRender role={AuthRoles.Editor} name={'ads'}>
            <Nav.Item>
                <Nav.Link eventKey="ads">Ads</Nav.Link>
            </Nav.Item>
        </AuthBasedRender>
        <Nav.Item>
            <Nav.Link eventKey="verticals">Verticals</Nav.Link>
        </Nav.Item>
        <AuthBasedRender role={AuthRoles.Analytics} name={'abTest'}>
            <Nav.Item>
                <Nav.Link eventKey="growthbook-config">Client AB-test</Nav.Link>
            </Nav.Item>
        </AuthBasedRender>
        
        <AuthBasedRender role={AuthRoles.Admin} name={'adsBundleVersion'}>
            <Nav.Item>
                <Nav.Link eventKey="ads-bundle-version">Ads Bundle Version</Nav.Link>
            </Nav.Item>
        </AuthBasedRender>
        <AuthBasedRender role={AuthRoles.Admin} name={'cache'}>
            <Nav.Item>
                <Nav.Link eventKey="cache">Cache</Nav.Link>
            </Nav.Item>
        </AuthBasedRender>
        
    </Nav>
)


export default TabTitleBar;