import React, { useContext } from "react";
import Layout from "../../layout/layout";
import AddSite from '../../addsite/addsite';
import styles from './options.module.scss'
import AddVerticals from "../../addverticals/addVerticals";
import verticalsContext from "../../stores/verticals/verticalsContext";
import { AuthBasedRender } from "../../stores/auth/authbasedrender";
import { AuthRoles } from "../../stores/auth/authRole";

const Options = () => {

    const VerticalsContext = useContext(verticalsContext);

    return (
        <Layout>
            <div className={styles.option_wrapper}>
                <AuthBasedRender role={AuthRoles.Admin}>
                    <div className={styles.option}>
                        <h2>Add site</h2>
                        <p>Enter the name of the site to add (e.g. allerbrand.com) and press the 'add site' button.</p>

                        <p>Enter "blog" as subdomain to get a blog site (e.g. blog.allerbrand.com), that is default for all subdomains</p>
                        <p>For a specific subdomain-site add something else than "blog" (e.g. newsubdomain.allerbrand.com).</p>

                        <AddSite />
                    </div>
                </AuthBasedRender>

                <div className={styles.option}>
                    <h2>Verticals</h2>
                    <p>Existing verticals visible in the list are not clickable and are only to be seen as a reference.</p>
                    <AddVerticals allowNew={true} onCallback={VerticalsContext.save} />
                </div>

            </div>
        </Layout>

    )
}

export default Options;
